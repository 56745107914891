import { url } from "../api";
import http from "./../utils/http";

export function updateClientsHubpost(){
  return http.get(`${url}/api/hubpost/client`);
}

export function getClient(id) {
  return http.post(`${url}/api/client`, JSON.stringify({ id }));
}

export function getClientsHubpost(id) {
  return http.post(`${url}/api/client`, JSON.stringify({ id }));
}

export async function updateFoto (formData){
  try {
    const response = await fetch(`${url}/api/client/agregarImagen`, {
        method: 'POST',
        body: formData
    });
    const data = await response.json();
    console.log(data);
} catch (error) {
    console.error('Error:', error);
}
}

export async function obtenerFoto (nombreImagen){
  return await http.post(`${url}/api/client/obtenerImagen`, JSON.stringify({ nombreImagen }));
}


